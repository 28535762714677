import { useRef } from "react";
import _ from "lodash";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import {
  formatToReadableNumber,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { CaptableTag } from "../../components/AllotmentTag";
import { getCurrency } from "../../utils/currencyFormatter";
import Avatar from "../Utility/Avatar";

export function ShareholderNameRender(data: any) {
  return (
    <td className="flex flex-col text-sm text-left pb-2">
      <div className="flex">
        <div className="text-left pr-2">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value && (
            <p className={tableFirstColStyle}>
              {limitString(data.value.name || "", 24)}
            </p>
          )}
          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function PreHoldingFdbSharesRender(data: any) {
  return (
    <td className=" whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingFdbShares,
          format: data.data.currencyType,
        })}
      </p>
    </td>
  );
}

export function PostHoldingFdbSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingFdbShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreHoldingFdbPercentage(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingFdbPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreHoldingCommonSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingCommonShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingCommonSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingCommonShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreHoldingPrefRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingPref || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingPrefRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingPref || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreHoldingDebRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingDeb || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingDebRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingDeb || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingFdbPercentage(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingFdbPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountInvestedRender(data: any) {
  const roundSymbol = getRoundCurrencySymbol(
    data.data.amountInvested.roundCurrency
  );
  const roundCurrency = getCurrency(data.data.amountInvested.roundCurrency);
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested.amount,
          format: data.data.currencyType,
        })}
      </p>
      {data.value.type !== "-" &&
        data.value.amount !== 0 &&
        data.value.type !== 0 &&
        roundCurrency !== data.data.currencyType && (
          <p className="text-xxs1 font-medium text-[#C2C2C2]">
            <span>{roundSymbol}</span>
            {formatToReadableNumber({
              value: data.value.type,
              format: roundCurrency || data.data.currencyType,
            })}
            <span> (Round)</span>
          </p>
        )}
    </div>
  );
}

export function TotalAmountInvestedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.totalAmountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Math.abs(data.data.numberOfShares),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NetworthRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Math.abs(data.data.networth),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function TransactionAmountRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Math.abs(data.data.transactionAmount),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
